import {Component, ViewChild} from '@angular/core';
import {RouterModule} from "@angular/router";
import {MatSidenav, MatSidenavContainer, MatSidenavContent} from "@angular/material/sidenav";
import {MatToolbar} from "@angular/material/toolbar";
import {MatButton, MatIconButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {MatList, MatListItem, MatNavList} from "@angular/material/list";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {NgOptimizedImage} from "@angular/common";
import {Icons} from "@shared/icons.constants";
import {PageHeaderComponent} from "@core/layout/components/page-header/page-header.component";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [RouterModule, MatSidenavContainer, MatSidenavContent, MatToolbar, MatIconButton, MatIcon, MatButton, MatNavList, MatListItem, MatSidenav, MatMenuTrigger, MatMenu, MatMenuItem, NgOptimizedImage, MatList, PageHeaderComponent],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss'
})
export class LayoutComponent {
  @ViewChild('menu') menu: MatSidenav | undefined;
  @ViewChild('account') account: MatSidenav | undefined;
  menuIcon: string = Icons.MENU;
  accountIcon: string = Icons.ACCOUNT;
  applicationName: string = environment.applicationName;

  menuToggle() {
    this.menu?.toggle();
    this.menuIcon = (this.menu?.opened) ? Icons.CLOSE : Icons.MENU;
  }

  accountToggle() {
    this.account?.toggle();
    this.accountIcon = (this.account?.opened) ? Icons.CLOSE : Icons.ACCOUNT;
  }

  logout() {
    console.log('CLICK ON >> logout')
  }

  protected readonly Icons = Icons;
}
